import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-5b685717"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  id: "pageList",
  class: "OaApplyList"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_date_picker = _resolveComponent("el-date-picker")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_ListEngine = _resolveComponent("ListEngine")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ListEngine, {
      pageList: _ctx.pageList,
      ref: "pageListRef",
      formatPageInfo: _ctx.formatPageInfo
    }, {
      queryParams: _withCtx(() => [
        _createVNode(_component_el_row, { class: "myRow" }, {
          default: _withCtx(() => [
            _createVNode(_component_el_col, {
              span: 3,
              class: "myColTitle"
            }, {
              default: _withCtx(() => [
                _createTextVNode("中信保账户：")
              ]),
              _: 1
            }),
            _createVNode(_component_el_col, { span: 9 }, {
              default: _withCtx(() => [
                _createVNode(_component_el_select, {
                  ref: el=>_ctx.refMap.set('oaZxbId',el),
                  modelValue: _ctx.pageList.queryParam.oaZxbId,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.pageList.queryParam.oaZxbId) = $event)),
                  placeholder: "请选择中信保账户",
                  clearable: "",
                  style: {"width":"100%"}
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.comboSelect({comboId:'oaZxbId'}), (item) => {
                      return (_openBlock(), _createBlock(_component_el_option, {
                        key: item.value,
                        label: item.label,
                        value: item.value
                      }, null, 8, ["label", "value"]))
                    }), 128))
                  ]),
                  _: 1
                }, 8, ["modelValue"])
              ]),
              _: 1
            }),
            _createVNode(_component_el_col, {
              span: 3,
              class: "myColTitle"
            }, {
              default: _withCtx(() => [
                _createTextVNode("客户：")
              ]),
              _: 1
            }),
            _createVNode(_component_el_col, { span: 9 }, {
              default: _withCtx(() => [
                _createVNode(_component_el_select, {
                  ref: el=>_ctx.refMap.set('customerId',el),
                  modelValue: _ctx.pageList.queryParam.customerId,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.pageList.queryParam.customerId) = $event)),
                  placeholder: "请选择客户",
                  clearable: "",
                  style: {"width":"100%"}
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.comboSelect({comboId:'customerId'}), (item) => {
                      return (_openBlock(), _createBlock(_component_el_option, {
                        key: item.value,
                        label: item.label,
                        value: item.value
                      }, null, 8, ["label", "value"]))
                    }), 128))
                  ]),
                  _: 1
                }, 8, ["modelValue"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_el_row, { class: "myRow" }, {
          default: _withCtx(() => [
            _createVNode(_component_el_col, {
              span: 3,
              class: "myColTitle"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('oaApply.queryParam.cyrq')) + "：", 1)
              ]),
              _: 1
            }),
            _createVNode(_component_el_col, { span: 9 }, {
              default: _withCtx(() => [
                _createVNode(_component_el_date_picker, {
                  modelValue: _ctx.pageList.queryParam.cyrq,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.pageList.queryParam.cyrq) = $event)),
                  type: "daterange",
                  shortcuts: _ctx.utils.Tools.defDateRange(),
                  style: {"width":"100%"}
                }, null, 8, ["modelValue", "shortcuts"])
              ]),
              _: 1
            }),
            _createVNode(_component_el_col, {
              span: 3,
              class: "myColTitle"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('oaApply.queryParam.shdqr')) + "：", 1)
              ]),
              _: 1
            }),
            _createVNode(_component_el_col, { span: 9 }, {
              default: _withCtx(() => [
                _createVNode(_component_el_date_picker, {
                  modelValue: _ctx.pageList.queryParam.shdqr,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.pageList.queryParam.shdqr) = $event)),
                  type: "daterange",
                  shortcuts: _ctx.utils.Tools.defDateRange(),
                  style: {"width":"100%"}
                }, null, 8, ["modelValue", "shortcuts"])
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      extendSlot: _withCtx(() => []),
      tbCols: _withCtx(() => [
        _createVNode(_component_el_table_column, {
          prop: "F_CODE",
          label: "中信保代码"
        }),
        _createVNode(_component_el_table_column, {
          prop: "F_CUSTOMER_NAME",
          label: "客户"
        }),
        _createVNode(_component_el_table_column, {
          prop: "F_AMOUNT",
          label: "使用限额",
          "header-align": "center",
          align: "right"
        }, {
          default: _withCtx((scope) => [
            _createElementVNode("span", null, _toDisplayString(_ctx.utils.UtilPub.toAmountFormat(scope.row.F_AMOUNT,false,2)), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_el_table_column, {
          prop: "F_BZ",
          label: "币种"
        }),
        _createVNode(_component_el_table_column, {
          prop: "F_PFBL",
          label: "赔付比例(%)"
        }),
        _createVNode(_component_el_table_column, {
          prop: "F_CURRENT_AMOUNT",
          label: "本次使用金额",
          "header-align": "center",
          align: "right"
        }, {
          default: _withCtx((scope) => [
            _createElementVNode("span", null, _toDisplayString(_ctx.utils.UtilPub.toAmountFormat(scope.row.F_CURRENT_AMOUNT,false,2)), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_el_table_column, {
          prop: "F_CYRQ",
          label: "出运日期"
        }),
        _createVNode(_component_el_table_column, {
          prop: "F_SHDQR",
          label: "收汇到期日"
        }),
        _createVNode(_component_el_table_column, {
          prop: "F_FLAG",
          label: "是否提交"
        })
      ]),
      _: 1
    }, 8, ["pageList", "formatPageInfo"])
  ]))
}